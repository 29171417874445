import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'moment/locale/hu';

import PageHeader from 'components/PageHeader';
import Tag from 'elements/Tag';

import { useConfig } from 'providers/ConfigProvider';
import { getByKeyValue } from 'utils/GenericUtils';

export default function ContentPage(props) {
    const [content, setContent] = useState(null);

    let { slug } = useParams();

    useEffect(() => {
        if (slug) {
            if (props.type && props.type === 'POST') {
                const url = global.endpoint + 'wp-json/wp/v2/posts?slug=' + slug;
                axios.get(url, {}).then(function (response) { if (response.data) setContent(response.data[0]); }).catch(function (error) { console.log(error); });
            } else {
                const url = global.endpoint + 'wp-json/wp/v2/pages?slug=' + slug;
                axios.get(url, {}).then(function (response) { if (response.data) setContent(response.data[0]); }).catch(function (error) { console.log(error); });
            }
        }
    }, [slug, props.type]);

    return (
        <div className='flex flex-col gap-16'>
            {content && <Content data={content} />}
        </div>
    );
}

function Content(props) {
    const [tagList, setTagList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const { categories, tags } = useConfig();

    const _d = {
        slug: props.data.slug,
        title: props.data.title.rendered,
        date: props.data.date,
        content: props.data.content.rendered,
        categories: props.data.categories,
        tags: props.data.tags,
    }

    useEffect(() => {
        let list = [];
        if (_d.tags) for (let i = 0; i < _d.tags.length; i++) if (getByKeyValue(tags, 'id', _d.tags[i])) list.push(getByKeyValue(tags, 'id', _d.tags[i]));
        setTagList(list);
    }, [tags, _d.tags]);

    useEffect(() => {
        let list = [];
        if (_d.categories) for (let i = 0; i < _d.categories.length; i++) if (getByKeyValue(categories, 'id', _d.categories[i])) list.push(getByKeyValue(categories, 'id', _d.categories[i]));
        setCategoryList(list);
    }, [categories, _d.categories]);

    return (
        <div className='w-full flex flex-col gap-4 md:gap-10'>

            <PageHeader title={_d.title} date={_d.date} />

            <div className='flex flex-col gap-6'>

                <div className='flex flex-col gap-8 p-8 md:p-10 bg-white/75 backdrop-blur-sm'>

                    <div className='leading-relaxed content-text' dangerouslySetInnerHTML={{ __html: _d.content }} />

                </div>

                {(tagList > 0 || categoryList.length > 0) && <div className='flex justify-between gap-8 text-xs text-neutral-400 font-sans uppercase'>

                    {categoryList.length > 0 && <div className='flex gap-2'>
                        <div>{categoryList.length > 1 ? 'Kategóriák' : 'Kategória'}:</div>
                        <div className='flex'>{categoryList.map((item, index) => (<>{index ? <>,&nbsp;</> : ''}<Link className='transition-colors hover:text-primary' key={item.id} to={'/kategoria/' + item.slug}>{item.name}</Link></>))}</div>
                    </div>}

                    {tagList.length > 0 && <div className='flex items-center gap-3'>
                        <div className=''>{tagList > 1 ? 'Címkék' : 'Címke'}:</div>
                        <div className='flex gap-2 flex-wrap'>
                            {tagList.map((item) => (<Tag key={item.id} label={item.name} slug={item.slug} />))}
                        </div>
                    </div>}

                </div>}

            </div>

        </div>
    );
}