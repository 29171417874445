import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { MdOutlineSearch, MdHourglassTop, MdClose } from 'react-icons/md';

import Modal from 'elements/Modal.js';
import Pagination from 'components/Pagination';

import { useConfig } from 'providers/WebsiteProvider';

export default function SearchModal(props) {
    const [term, setTerm] = useState('');

    const [list, setList] = useState([]);
    const [progress, setProgress] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const { setSearchModalVisible } = useConfig();

    const abortRef = useRef(null);

    useEffect(() => {
        if (term !== '') {
            if (abortRef.current != null) abortRef.current.abort();
            abortRef.current = new AbortController();

            let url = global.endpoint + 'wp-json/wp/v2/posts?search=' + term + '&_embed' + '&page=' + page;

            setProgress(true);

            axios.get(url, { signal: abortRef.current.signal }).then(function (response) {
                abortRef.current = null;
                if (response.data) {
                    setProgress(false);
                    setCount(response.headers['x-wp-total']);
                    setPageCount(response.headers['x-wp-totalpages']);
                    setList(response.data);
                }
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            clearSearch();
        }
    }, [term, page]);

    const clearSearch = () => {
        setTerm('');

        if (abortRef.current != null) abortRef.current.abort();

        setProgress(false);
        setList([]);
        setPage(1);
        setCount(0);
        setPageCount(0);
    };

    return (
        <Modal innerClassName='gap-4' onClose={() => setSearchModalVisible(false)}>

            <div className='flex flex-col md:flex-row items-start md:items-center gap-4 md:gap-8'>

                <div className='font-display text-3xl'>Keresés</div>

                <div className='w-full h-full flex items-center'>

                    <input
                        className='flex-1 outline-none bg-white/10 focus:bg-white/20 text-white py-2 px-3 placeholder:text-white/50 text-sm transition-colors'
                        placeholder='Adja meg a keresni kívánt kifejezést'
                        value={term}
                        onChange={(e) => setTerm(e.target.value)} />

                    <button className='h-full aspect-square bg-white/10  flex items-center justify-center' onClick={() => clearSearch()} disabled={term === ''}>
                        {term === '' && <MdOutlineSearch className='text-lg' />}
                        {term !== '' && <MdClose className='text-lg' />}
                    </button>

                </div>

            </div>

            {term !== '' && <div className='w-full bg-white/5 flex items-center justify-center p-2 text-sm'>

                {progress && <div className='overflow-hidden text-center'>
                    <MdHourglassTop className='text-[14px] leading-none inline' />
                    &nbsp;A keresés folyamatban: <span className='font-bold break-words'>{term}</span>
                </div>}

                {!progress && <div className='text-xs'>A keresett kifejezés: <span className='font-bold'>{term}</span>, a találatok száma:  <span className='font-bold'>{count}</span></div>}

            </div>}

            {(list && list.length > 0) && <div className='flex flex-col gap-8'>

                <div className='flex flex-col gap-4'>
                    {list && list.map((item) => (<SearchResultItem key={item.slug} data={item} />))}
                </div>

                {pageCount > 1 && <Pagination itemClassName='text-neutral-400 hover:text-white' activeItemClassName='text-primary' pageCount={pageCount} page={page} onPageChanged={(page) => setPage(page)} />}

            </div>}

        </Modal>
    );
}

function SearchResultItem(props) {
    const { setSearchModalVisible } = useConfig();

    const _d = {
        slug: props.data.slug,
        title: props.data.title.rendered,
        excerpt: props.data.excerpt.rendered,
        date: props.data.date,
        categories: props.data.categories,
        tags: props.data.tags,
    }

    return (
        <Link to={'/tartalom/' + _d.slug} className='bg-white/10 hover:bg-white/20 p-6 flex flex-col gap-4 transition-colors group' onClick={() => setSearchModalVisible(false)}>

            <div className='font-display text-xl font-bold transition-colors' dangerouslySetInnerHTML={{ __html: _d.title }} />

            {_d.excerpt && <div className='font-serif font-medium text-xs' dangerouslySetInnerHTML={{ __html: _d.excerpt }} />}

        </Link>
    );
}