import axios from 'axios';
import { useState, useEffect } from 'react';
import { NavLink, Link, useParams } from 'react-router-dom';

export default function Menu(props) {
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        if (props.slug) {
            const url = global.endpoint + 'wp-json/menus/v1/menus/' + props.slug;
            axios.get(url, {}).then(function (response) { if (response.data) setMenu(response.data); }).catch(function (error) { console.log(error); });
        }
    }, [props.slug]);

    return (
        <div className={'flex flex-col gap-1 md:gap-2 ' + (props.className || '')}>
            {menu && menu.items.map((item) => <MenuItem key={item.ID} data={item} />)}
        </div>
    );
}

function MenuItem(props) {
    const _d = {
        title: props.data.title,
        object: props.data.object,
        slug: props.data.slug,
        url: props.data.url,
    }

    const getPath = () => {
        let path = '/';
        if (_d.url.startsWith('#!')) {
            path += _d.url.replace('#!', '');
        } else {
            if (_d.object === 'page') path += 'oldal/' + _d.slug;
            if (_d.object === 'category') path += 'kategoria/' + _d.slug;
        }
        return path;
    };

    return (
        <NavLink end to={getPath()} className={({ isActive }) => ('text-base md:text-xl text-white transition' + (isActive ? '' : ' opacity-50 hover:opacity-100'))}>

            {_d.title}

        </NavLink>
    );
}