
import './global.js';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from 'components/Header.js';
import SideBar from 'components/SideBar.js';
import SearchModal from 'elements/SearchModal.js';

import ListPage from 'pages/ListPage';
import ContentPage from 'pages/ContentPage';
import BookListPage from 'pages/BookListPage';
import TagCloudPage from 'pages/TagCloudPage';
import Error404Page from 'pages/Error404Page.js';

import WebsiteProvider from 'providers/WebsiteProvider';
import ConfigProvider from 'providers/ConfigProvider';

import { useConfig } from 'providers/WebsiteProvider';

import ScrollToTop from 'utils/ScrollToTop';

export default function App() {
  return (
    <WebsiteProvider>
      <ConfigProvider>

        <Router>

          <Miscellaneous />

          <div className='w-full flex flex-col items-center'>

            <Header className='flex md:hidden' />

            <div className='w-full flex'>

              <div className='hidden md:flex fixed h-full w-[40%] xl:w-[30%] bg-neutral-900 justify-end p-12 xl:p-24'>
                <SideBar className='hidden md:flex w-[225px] z-10' />
                <div className='absolute bg-bottom bg-contain bg-no-repeat w-full h-full grayscale opacity-[0.0125]' style={{ backgroundImage: 'url(/assets/images/artifact.png)' }}></div>
              </div>

              <div className=' md:ml-[40%] xl:ml-[30%] min-h-screen flex-1 p-6 md:p-16 shadow-2xl bg-neutral-50 overflow-hidden'>

                <div className='fixed -top-[30%] -right-[35%] bg-bottom bg-contain bg-no-repeat w-full h-full grayscale opacity-[0.025] rotate-180' style={{ backgroundImage: 'url(/assets/images/artifact.png)' }}></div>

                <div className='min-h-full flex flex-col relative z-10'>
                  <Routes>
                    <Route path='/' element={<ListPage />} />
                    <Route path='/tartalom/:slug' element={<ContentPage type='POST' />} />
                    <Route path='/oldal/:slug' element={<ContentPage />} />
                    <Route path='/kategoria/:slug' element={<ListPage type='CATEGORY' />} />
                    <Route path='/cimke/:slug' element={<ListPage type='TAG' />} />
                    <Route path='/kiadvanyok' element={<BookListPage />} />
                    <Route path='/cimkefelho' element={<TagCloudPage />} />
                    <Route path='*' element={<Error404Page />} />
                  </Routes>
                </div>

              </div>

            </div>

          </div>

        </Router>

      </ConfigProvider>
    </WebsiteProvider>
  );
}

function Miscellaneous(props) {
  const { searchModalVisible } = useConfig();

  return (
    <>
      <ScrollToTop />
      {searchModalVisible && <SearchModal />}
    </>
  );
}