import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import 'moment/locale/hu';
import { FaCalendar } from 'react-icons/fa';

import PageHeader from 'components/PageHeader';
import Pagination from 'components/Pagination';

export default function BookListPage(props) {
    const [list, setList] = useState(null);

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const abortRef = useRef(null);

    useEffect(() => {
        if (abortRef.current != null) abortRef.current.abort();
        abortRef.current = new AbortController();

        let url = global.endpoint + 'wp-json/wp/v2/konyv?per_page=' + /*global.post_per_page*/100 + ('&page=' + page) + '&_embed';

        axios.get(url, { signal: abortRef.current.signal }).then(function (response) {
            abortRef.current = null;
            if (response.data) {
                setPageCount(response.headers['x-wp-totalpages']);
                setList(response.data.reverse());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, [page]);

    return (
        <div className='w-full flex flex-col gap-4 md:gap-10'>

            <PageHeader title='Megjelent kötetek' />

            <div className='grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6'>
                {list && list.map((item) => (<ListItem key={item.id} data={item} />))}
            </div>

            {pageCount > 1 && <Pagination pageCount={pageCount} page={page} onPageChanged={(page) => setPage(page)} />}

        </div>
    );
}

function ListItem(props) {
    const _d = {
        image: (props.data._embedded && props.data._embedded['wp:featuredmedia']) && props.data._embedded['wp:featuredmedia'][0].source_url,
        title: props.data.title.rendered,
        date: props.data.date,
        description: props.data.acf.leiras,
        publication: props.data.acf.megjelenes,
    }

    return (
        <div className='flex flex-col gap-4 p-4 md:p-6 bg-white/75 backdrop-blur-sm'>

            {_d.image && <img className='w-full' src={_d.image} alt={_d.title}></img>}

            <div className='flex-1 flex flex-col gap-4 justify-between'>

                <div className='flex flex-col gap-2'>
                    <div className='font-display font-bold text-lg leading-snug mb-1'>{_d.title}</div>
                    {_d.description && <div className='text-sm'>{_d.description}</div>}
                    {_d.publication && <div className='text-xs font-semibold'>{_d.publication}</div>}
                </div>

                <div>
                    <div className='flex items-center gap-1.5 font-sans text-neutral-400 text-xs uppercase'><FaCalendar className='text-[10px]' /><Moment format='YYYY. MMMM DD.' withTitle>{new Date(_d.date)}</Moment></div>
                </div>

            </div>

        </div >
    );
}