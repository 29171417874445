import axios from 'axios';
import { useState, useEffect, createContext, useContext } from 'react';

export const ConfigContext = createContext(null);
export const useConfig = () => { return useContext(ConfigContext); };

export default function ConfigProvider(props) {
	const [categories, setCategories] = useState([]);
	const [tags, setTags] = useState([]);

	useEffect(() => {
		const url = global.endpoint + 'wp-json/wp/v2/categories'
		const body = {}
		axios.get(url, body).then(function (response) { if (response.data) setCategories(response.data); }).catch(function (error) { console.log(error); });
	}, []);

	useEffect(() => {
		const url = global.endpoint + 'wp-json/wp/v2/tags?per_page=999'
		const body = {}
		axios.get(url, body).then(function (response) { if (response.data) setTags(response.data); }).catch(function (error) { console.log(error); });
	}, []);

	const value = {
		categories,
		tags,
	};

	return (
		<ConfigContext.Provider value={value}>
			{props.children}
		</ConfigContext.Provider>
	);
}
