import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineSearch, MdOutlineCloud } from 'react-icons/md';

import Menu from 'components/Menu';

import { useConfig } from 'providers/WebsiteProvider';

export default function SideBar(props) {
    return (
        <div className={'flex flex-col justify-center ' + (props.className || '')}>

            <Content className='items-end' />

        </div>
    );
}

function Content(props) {
    return (
        <div className={'flex flex-col gap-12 justify-center ' + (props.className || '')}>

            <Heading />

            <Menu slug='fomenu' className='items-center md:items-end' />

            <div className='flex flex-col gap-4 items-center md:items-end'>

                <StaticMenu />

                <Copyright />

            </div>

        </div>
    );
}

function Heading(props) {
    return (
        <Link to='/' className='w-full flex flex-col gap-8 md:gap-6 text-white md:text-white'>

            {/* <div className='text-4xl xl:text-4xl text-center md:text-right font-bold font-display'>Steinbach József</div> */}

            <div className='text-5xl text-center md:text-right font-bold font-display inline'>
                <div className=''>Steinbach</div>
                <div className='text-6xl'>József</div>
            </div>


            <div className='flex flex-col md:flex-row items-center justify-between gap-0 md:gap-2 text-sm md:text-sm italic text-primary md:text-primary'>
                <div className=''>Nagyok az Úr tettei!</div>
                <div className='hidden md:block h-px flex-1 bg-primary' />
                <div className=''>(Zsoltárok 11,2)</div>
            </div>

        </Link>
    );
}

function StaticMenu(props) {
    const { setSearchModalVisible } = useConfig();

    return (
        <div className='flex'>
            <StaticMenuItem icon={<MdOutlineSearch />} label='Keresés' path='' onClick={() => setSearchModalVisible(true)} />
            <StaticMenuItem icon={<MdOutlineCloud />} label='Címkefelhő' path='/cimkefelho' />
        </div>
    );
};

function StaticMenuItem(props) {
    return (
        <Link to={props.path} className='px-2.5 first:pl-0 last:pr-0 border-white/20 border-r last:border-r-0' onClick={props.onClick}>
            <div className='flex items-center gap-1 text-white opacity-50 hover:opacity-100 transition '>
                <div className='text-sm'>{props.icon}</div>
                <div className='text-xs'>{props.label}</div>
            </div>
        </Link>
    );
};

function Copyright(props) {
    return (
        <div className='flex flex-col gap-2'>

            <div className='w-full text-center text-sm text-white md:text-blawhiteck'>© Steinbach József, {new Date().getFullYear()}</div>

        </div>
    );
}

export function Slider(props) {
    let location = useLocation();

    useEffect(() => {
        if (props.onSliderClose()) props.onSliderClose();
    }, [location]);

    return (
        <motion.div
            className={'fixed top-0 left-0 w-full h-full z-40 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            transition={{ duration: .2 }}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0, transitionEnd: { display: 'none' } } }}>

            <div className='absolute w-full h-full bg-white/20 ' onClick={() => props.onSliderClose()} />

            <div className='absolute left-0 h-full w-[70%]'>

                <motion.div
                    className='bg-neutral-900 h-full flex flex-col items-center justify-center p-8'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    transition={{ duration: 0.4 }}
                    variants={{ visible: { translateX: 0, display: 'flex' }, hidden: { translateX: '-100%', transitionEnd: { display: 'none' } } }}>

                    <Content className='items-center' />

                </motion.div>

            </div>

        </motion.div>
    );
}