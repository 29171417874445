import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment/locale/hu';
import { FaCalendar } from 'react-icons/fa';

import PageHeader from 'components/PageHeader';
import Pagination from 'components/Pagination';
import Tag from 'elements/Tag';

import { useConfig } from 'providers/ConfigProvider';
import { getByKeyValue } from 'utils/GenericUtils';

export default function ListPage(props) {
    const [category, setCategory] = useState(null);
    const [tag, setTag] = useState(null);
    const [list, setList] = useState(null);

    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);

    const abortRef = useRef(null);

    let { slug } = useParams();
    const { categories, tags } = useConfig();

    useEffect(() => {
        if (slug && props.type) {
            if (props.type === 'CATEGORY') {
                setTag(null);
                setCategory(getByKeyValue(categories, 'slug', slug));
            }
            if (props.type === 'TAG') {
                setTag(getByKeyValue(tags, 'slug', slug));
                setCategory(null);
            }
        } else {
            setCategory(null);
            setTag(null);
        }
        setPage(1);
    }, [slug, categories, tags, props.type]);

    useEffect(() => {
        if (abortRef.current != null) abortRef.current.abort();
        abortRef.current = new AbortController();

        let url = global.endpoint + 'wp-json/wp/v2/posts?per_page=' + global.post_per_page + ('&page=' + page) + (category ? ('&categories=' + category.id) : '');

        if (category) url += '&categories=' + category.id;
        if (tag) url += '&tags=' + tag.id;

        url += '&_embed';

        axios.get(url, { signal: abortRef.current.signal }).then(function (response) {
            abortRef.current = null;
            if (response.data) {
                setPageCount(response.headers['x-wp-totalpages']);
                setList(response.data);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }, [category, tag, page]);

    return (
        <div className='w-full flex flex-col gap-4 md:gap-10'>

            <PageHeader
                title={category ? category.name : (tag ? tag.name : 'Legutóbbi írások')}
                subTitle={category ? 'Kategória' : (tag ? 'Címke' : 'Legutóbbi tartalom')}
                date={list && list[0].date}
                dateTo={list && list[list.length - 1].date} />

            {list && <>

                <div className='flex flex-col gap-6 md:gap-10'>
                    {list && list.map((item) => (<ListItem key={item.id} data={item} />))}
                </div>

                {pageCount > 1 && <Pagination pageCount={pageCount} page={page} onPageChanged={(page) => setPage(page)} />}

            </>}

        </div>
    );
}

function ListItem(props) {
    const [tagList, setTagList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const { categories, tags } = useConfig();

    const _d = {
        image: (props.data._embedded && props.data._embedded['wp:featuredmedia']) && props.data._embedded['wp:featuredmedia'][0].source_url,
        slug: props.data.slug,
        title: props.data.title.rendered,
        excerpt: props.data.excerpt.rendered,
        date: props.data.date,
        categories: props.data.categories,
        tags: props.data.tags,
    }

    useEffect(() => {
        let list = [];
        for (let i = 0; i < _d.tags.length; i++) if (getByKeyValue(tags, 'id', _d.tags[i])) list.push(getByKeyValue(tags, 'id', _d.tags[i]));
        setTagList(list);
    }, [tags, _d.tags]);

    useEffect(() => {
        let list = [];
        for (let i = 0; i < _d.categories.length; i++) if (getByKeyValue(categories, 'id', _d.categories[i])) list.push(getByKeyValue(categories, 'id', _d.categories[i]));
        setCategoryList(list);
    }, [categories, _d.categories]);

    return (
        <div className='flex flex-col xl:flex-row xl:items-stretch bg-white/75 backdrop-blur-sm'>

            <div className='flex-1 flex flex-col justify-between gap-6 p-6 md:p-10 order-2 xl:order-1'>

                <div className='flex flex-col gap-6'>

                    <div className='flex flex-col gap-2'>

                        <Link to={'/tartalom/' + _d.slug}><div className='font-display text-xl md:text-2xl xl:text-3xl font-bold hover:text-primary transition-colors' dangerouslySetInnerHTML={{ __html: _d.title }} /></Link>

                        <div className='flex font-sans text-neutral-400 text-xs uppercase'>
                            <div className='flex items-center gap-1.5'><FaCalendar className='text-[10px]' /><Moment format='YYYY. MMMM DD.' withTitle>{new Date(_d.date)}</Moment></div>
                            &nbsp;&nbsp;//&nbsp;&nbsp;
                            {categoryList.length > 0 && categoryList.map((item, index) => (<>{index ? <>,&nbsp;</> : ''}<Link className='transition-colors hover:text-primary' key={item.id} to={'/kategoria/' + item.slug}>{item.name}</Link></>))}
                        </div>

                    </div>

                    {_d.excerpt && <div className='font-serif font-medium text-base' dangerouslySetInnerHTML={{ __html: _d.excerpt }} />}

                </div>

                {tagList.length > 0 && <div className='flex gap-2 flex-wrap'>
                    {tagList.map((item) => (<Tag key={item.id} label={item.name} slug={item.slug} />))}
                </div>}

            </div>

            {_d.image && <div className='relative w-full self-strech xl:w-64 2xl:w-80 flex items-center justify-center xl:p-6 overflow-hidden order-1 xl:order-2'>

                <div className='absolute top-0 left-0 w-full h-full scale-110 bg-cover bg-center grayscale-[80%] opacity-20 blur-sm' style={{ backgroundImage: 'url(' + _d.image + ')' }} />

                {/* <div className='w-full h-full z-10 block overflow-hidden'> */}
                <img className='max-h-full max-h-full z-10 shadow-xl' src={_d.image} alt={_d.title} />

                {/* </div> */}

            </div>}

        </div>
    );
}