
import Moment from 'react-moment';
import 'moment/locale/hu';
import { FaCalendar } from 'react-icons/fa';

export default function PageHeader(props) {
    const getFontSize = (l) => {
        console.log(l);
        let fs = 'text-4xl md:text-5xl xl:text-6xl';
        if (l > 64) fs = 'text-3xl md:text-4xl xl:text-5xl';
        return fs;
    };

    return (
        <div className={'flex flex-col md:flex-row md:flex-wrap items-start md:items-end justify-between gap-4 md:gap-8 border-neutral-200 pb-4'}>

            {props.title && <div className={'font-display font-extrabold ' + (getFontSize(props.title.length))} dangerouslySetInnerHTML={{ __html: props.title }} />}

            <div className='hidden md:block flex-1 h-px w-full bg-neutral-200 mb-1'></div>

            <div className='flex items-center gap-1.5 font-sans text-neutral-400 text-xs uppercase'>

                {props.subTitle && <div>{props.subTitle}</div>}

                {(props.subTitle && props.date) && <>&nbsp;&nbsp;//&nbsp;&nbsp;</>}

                <DateBlock {...props} />

            </div>

        </div>
    );
}

function DateBlock(props) {
    return (
        <>

            {props.date && <>
                <FaCalendar className='text-[10px]' />
                <>
                    <Moment className='hidden 2xl:block' format='YYYY. MMMM DD.' withTitle>{new Date(props.date)}</Moment>
                    <Moment className='block 2xl:hidden' format='YYYY.MM.DD.' withTitle>{new Date(props.date)}</Moment>
                </>
            </>}

            {props.dateTo && <>
                &nbsp;-&nbsp;
                <Moment className='hidden 2xl:block' format='YYYY. MMMM DD.' withTitle>{new Date(props.dateTo)}</Moment>
                <Moment className='block 2xl:hidden' format='YYYY.MM.DD.' withTitle>{new Date(props.dateTo)}</Moment>
            </>}

        </>
    );
}