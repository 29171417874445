
import { useState, useEffect } from 'react';
import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa';

const limit = 10;

export default function Pagination(props) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(false);
    }, [props.pageCount]);

    useEffect(() => {
        if (getItems('MIDDLE').indexOf(props.page - 1) > -1) setOpen(true);
    }, [props.page]);

    const getItems = (type) => {
        let arr = [];

        if (type === 'ALL') for (let i = 0; i < props.pageCount; i++) arr.push(i);
        if (type === 'FIRST') for (let i = 0; i < Math.floor(limit / 2); i++) arr.push(i);
        if (type === 'MIDDLE') for (let i = Math.floor(limit / 2); i < props.pageCount - Math.floor(limit / 2); i++) arr.push(i);
        if (type === 'LAST') for (let i = props.pageCount - Math.floor(limit / 2); i < props.pageCount; i++) arr.push(i);

        return arr;
    };

    const onClick = (index) => {
        if (!props.disableScroll) window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onPageChanged(index + 1)
    };

    const isActive = (index) => {
        return props.page === index + 1;
    };

    const itemClassName = props.itemClassName ? props.itemClassName : 'text-neutral-400 hover:text-primary';
    const activeItemClassName = props.activeItemClassName ? props.activeItemClassName : 'font-bold text-black';
    const arrowClassName = 'm-1 group-disabled:opacity-25 group-disabled:hover:text-neutral-400 ' + itemClassName;

    return (
        <div className='flex flex-row items-center justify-center flex-wrap'>

            <button className='group' disabled={props.page === 1} onClick={() => onClick(props.page - 2)}><FaLongArrowAltLeft className={arrowClassName} /></button>

            {props.pageCount <= limit && getItems('ALL').map((index) => <Item key={index} className={itemClassName} activeClassName={activeItemClassName} index={index} active={isActive(index)} onClick={() => onClick(index)} />)}

            {props.pageCount > limit && <>

                {getItems('FIRST').map((index) => <Item key={index} className={itemClassName} activeClassName={activeItemClassName} index={index} active={isActive(index)} onClick={() => onClick(index)} />)}
                {open ? getItems('MIDDLE').map((index) => <Item key={index} className={itemClassName} activeClassName={activeItemClassName} index={index} active={isActive(index)} onClick={() => onClick(index)} />) : <button className={'px-2 font-bold ' + (itemClassName || '')} onClick={() => setOpen(true)}>...</button>}
                {getItems('LAST').map((index) => <Item key={index} className={itemClassName} activeClassName={activeItemClassName} index={index} active={isActive(index)} onClick={() => onClick(index)} />)}

            </>}

            <button className='group' disabled={props.page == props.pageCount} onClick={() => onClick(props.page)}><FaLongArrowAltRight className={arrowClassName} /></button>

        </div>
    );
}

function Item(props) {
    return (
        <button className={'p-1 font-sans font-medium transition-colors ' + (props.className || '')} onClick={props.onClick}>
            <div className={' ' + (props.active ? props.activeClassName : '')}>{props.index + 1}</div>
        </button>
    );
}