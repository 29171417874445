import Portal from 'react-overlays/Portal';
import { MdOutlineClose } from 'react-icons/md';

export default function Modal(props) {
    return (
        <Portal>

            <div className='fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-[1000] p-8' >

                <div className='fixed top-0 left-0 w-full h-full bg-black/60 backdrop-blur-lg' onClick={() => props.onClose()} />

                <div className={'w-full max-w-xl max-h-full flex flex-col items-center justify-center z-10 ' + (props.className || '')}>

                    <div className={'w-full h-full flex flex-col p-8 shadow-3xl overflow-auto bg-neutral-900 text-white ' + (props.innerClassName ? props.innerClassName : '')}>

                        {props.children}

                    </div>

                    <button className='lg:fixed w-full max-w-2xl lg:w-10 lg:h-10 p-4 lg:p-0 bg-white/20 hover:bg-white/30 transition top-0 right-0 flex items-center justify-center text-white mt-8 lg:m-8' onClick={() => props.onClose()}>
                        <MdOutlineClose />
                    </button>

                </div>

            </div>

        </Portal>
    );
}