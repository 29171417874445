import { useState, createContext, useContext } from 'react';

export const WebsiteContext = createContext(null);
export const useConfig = () => { return useContext(WebsiteContext); };

export default function WebsiteProvider(props) {
	const [searchModalVisible, setSearchModalVisible] = useState(false);

	const value = {
		searchModalVisible,
		setSearchModalVisible,
	};

	return (
		<WebsiteContext.Provider value={value}>
			{props.children}
		</WebsiteContext.Provider>
	);
}
