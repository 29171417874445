import { useState, useEffect } from 'react';
import { Spin as Hamburger } from 'hamburger-react'

import { Slider } from 'components/SideBar';

export default function Menu(props) {
    const [sliderOpen, setSliderOpen] = useState(false);

    return (
        <>

            <div className={'relative w-full px-6 py-5 bg-neutral-900 flex items-center overflow-hidden ' + (props.className || '')}>

                <div className='absolute  -top-2/3 bg-center bg-cover bg-no-repeat w-[65%] aspect-[1417/1682] grayscale opacity-[0.02] rotate-90 ' style={{ backgroundImage: 'url(/assets/images/artifact.png)' }}></div>

                <div className='text-white flex flex-col gap-0.5 z-10'>
                    <div className='text-2xl font-bold font-display'>Steinbach József</div>
                    <div className='text-xs text-primary italic'>Nagyok az Úr tettei! (Zsoltárok 11,2)</div>
                </div>

                <div className='size-20 flex items-center justify-center fixed right-0 z-50'>

                    <div className='bg-neutral-900/75' onClick={() => setSliderOpen(!sliderOpen)}>
                        <Hamburger size={22} color={'#ffffff'} direction='left' roundedd toggled={sliderOpen} />
                    </div>

                </div>


            </div>

            <Slider className='flex md:hidden' open={sliderOpen} onSliderClose={() => setSliderOpen(false)} />

        </>
    );
}