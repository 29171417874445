import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'moment/locale/hu';
import { TagCloud } from 'react-tagcloud'

import { useConfig } from 'providers/ConfigProvider';

export default function Error404Page(props) {
    return (
        <div className='w-full flex flex-col items-center justify-center flex-1'>

            <div className='font-black text-[128px] font-display text-primary'>404</div>
            <div className='font-semibold'>A keresett oldal sajnos nem található.</div>

        </div>
    );
}