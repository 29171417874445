import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'moment/locale/hu';
import { TagCloud } from 'react-tagcloud'

import { useConfig } from 'providers/ConfigProvider';

export default function ListPage(props) {
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);

    const { tags } = useConfig();

    useEffect(() => {
        if (tags && tags.length > 0) {
            let _data = [];
            let _count=0;
            for (let i = 0; i < tags.length; i++) {
                _data.push({ value: tags[i].name, count: tags[i].count });
                _count+=tags[i].count
            }
            setData(_data);
            setCount(_count);
        }
    }, [tags]);

    const getSlugByValue = (value) => {
        for (let i = 0; i < tags.length; i++) if (tags[i].name === value) return tags[i].slug;
        return null;
    };

    const customRenderer = (tag, size) => {
        return (
            <Link to={'/cimke/' + getSlugByValue(tag.value)} className='text-center font-display text-white p-2 leading-none opacity-90 hover:opacity-100 hover:scale-105 transition ' style={{ fontSize: (Math.min(Math.pow(size, 2), 42)) + 'px' }} key={tag.value}>
                {tag.value}
            </Link>
        )
    }

    return (
        <div className='w-full flex flex-col gap-8'>

            <div className='w-full flex flex-col gap-2 items-center justify-center text-center bg-white p-6 md:px-16 md:py-8'>
                <div className='text-2xl md:text-5xl font-display font-bold'>A weboldal címkefelhője</div>
                <div className='text-sm italic'>Összesen {tags.length} címke, és {count} hivatkozás</div>
            </div>

            <div className='bg-primary/90 flex items-center justify-center p-6 md:p-16'>
                <TagCloud
                    className='flex flex-wrap items-center justify-center'
                    minSize={4}
                    maxSize={20}
                    tags={data}
                    renderer={customRenderer}
                    disableRandomColor={true}
                    shuffle={false} />
            </div>

        </div>
    );
}